<template>
  <v-container>
    <!-- Banner header -->
    <Banner :title="'Añade, edita y elimina inversores fondo'">
      <template v-slot:actions>
        <v-btn v-show="permissionsBannerActions.create.includes(user.role)" @click="handleBtnOpenDialogCreateInvestor()">
          <v-icon>add</v-icon>
        </v-btn>
      </template>
    </Banner>

    <!-- Data table fund investors -->
    <v-card class="my-8">
      <DataTable
        :headers="tableHeaders"
        :items="investors"
        :totalItems="$store.state.investors.totalCountInvestors"
        @clickNextIcon="handleNextIcon"
        @clickPrevIcon="handlePrevIcon"
        @inputSearch="handleSearch"
        @changeItemsPerPage="getInvestors"
      >
        <template v-slot:[`item.id`]="{ item }">
          <router-link :to="`/inversores/fund/${item.id}`">{{ item.id }}</router-link>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ formatDateL(item.created_at) }}
        </template>
      </DataTable>
    </v-card>

    <!-- Dialog create investor -->
    <DialogBox :model="dialogCreateInvestor">
      <template slot="toolbar">
        <span>Crear inversor fondo</span>
      </template>
      <template slot="content">
        <v-form ref="formCreateInvestor" v-model="formCreateInvestorValid">
          <v-text-field v-model="investor.first_name" label="Nombre" :rules="formRules.textRules" />
          <v-text-field v-model="investor.identity_document" label="Documento de identidad" :rules="formRules.textRules" />
          <CountryAutocomplete
            ref="countryAutocomplete"
            :countryId="investor.country"
            :editable="true"
            @selected="investor.country = $event"
          />
          <v-select
            v-model="investor.investor.commission"
            :items="commissions"
            item-value="key"
            item-text="value.name"
            :rules="[(v) => !_.isEmpty(v) || 'Campo obligatorio']"
            label="Comisión"
          />
          <v-select
            v-model="investor.investor.favorite_currency"
            :items="currencies.filter((e) => e.investable === true)"
            item-text="name"
            item-value="id"
            label="Moneda preferida"
            :rules="[(v) => !!v || 'Campo obligatorio']"
          />
          <p class="mt-2"><b>Tipos de cambio por partner:</b></p>
          <div v-for="(exchangeRate, i) in exchangeRates" :key="i" class="secondary pa-5 mb-2">
            <div class="text-right">
              <v-btn icon x-small color="red" @click="exchangeRates.splice(i, 1)">
                <v-icon>delete</v-icon>
              </v-btn>
            </div>
            <v-row>
              <v-col cols="8">
                <v-autocomplete
                  v-model="exchangeRate.partner"
                  :items="partners"
                  :item-disabled="isPartnerSelected"
                  item-text="name"
                  label="Partner"
                  return-object
                  :rules="[(v) => !_.isEmpty(v) || 'Campo obligatorio']"
                >
                  <template slot="selection" slot-scope="data"> {{ data.item.name }} </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model.number="exchangeRate.value" type="number" label="Tipo de cambio" :rules="formRules.numberRules" />
              </v-col>
            </v-row>
          </div>
          <div v-if="exchangeRates.length !== partners.length" class="text-right">
            <v-btn elevation="2" small @click="exchangeRates.push({ partner: {}, value: 0 })">
              <v-icon>add</v-icon>
            </v-btn>
          </div>
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog('dialogCreateInvestor', 'formCreateInvestor')">Cerrar</v-btn>
        <v-btn text @click="create()">Crear</v-btn>
      </template>
    </DialogBox>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import Banner from "@/components/elements/Banner";
import DataTable from "@/components/elements/DataTable";
import DialogBox from "@/components/elements/DialogBox";
import CountryAutocomplete from "@/components/elements/locations/CountryAutocomplete.vue";

export default {
  name: "Investors",
  components: {
    Banner,
    DataTable,
    DialogBox,
    CountryAutocomplete
  },
  mixins: [FormatDateMixin, FormRulesMixin],
  data() {
    return {
      // Banner actions
      permissionsBannerActions: {
        create: ["SUPERADMIN", "ADMIN"]
      },

      // Investors datatable
      tableHeaders: [
        { text: "Id", value: "id", sortable: false },
        { text: "Documento de identidad", value: "identity_document", sortable: false },
        { text: "Nombre", value: "first_name", sortable: false },
        { text: "Fecha creación", value: "created_at" }
      ],

      // Dialogs
      dialogCreateInvestor: false,
      formCreateInvestorValid: false,

      // Fields of investor
      investor: {
        first_name: "",
        identity_document: "",
        country: "",
        investor: {
          commission: "",
          favorite_currency: ""
        }
      },
      exchangeRates: []
    };
  },
  computed: {
    ...mapState({
      investors: (state) => state.investors.investors,
      partners: (state) => state.partners.partners,
      user: (state) => state.user.user,
      commissions: (state) => state.investments.commissions,
      currencies: (state) => state.currencies.currencies
    }),
    _() {
      return _;
    }
  },
  async created() {
    await this.getInvestors({
      size: 10,
      filter: {
        type: "FUND"
      },
      sort: { created_at: "DESC" }
    });
  },
  methods: {
    ...mapActions({
      getInvestors: "investors/getInvestors"
    }),

    async handlePrevIcon(elasticData) {
      await this.getInvestors({ ...elasticData, filter: { type: "FUND" } });
    },

    async handleNextIcon(elasticData) {
      await this.getInvestors({ ...elasticData, filter: { type: "FUND" } });
    },

    async handleSearch(elasticData) {
      await this.getInvestors({ ...elasticData, filter: { type: "FUND" } });
    },

    // Create investor
    async create() {
      if (this.$refs.formCreateInvestor.validate()) {
        let investorFormattedData = { ...this.investor };

        if (this.exchangeRates.length) {
          investorFormattedData.investor.exchange_rates = {};
          for (const echangeRate of this.exchangeRates) {
            investorFormattedData.investor.exchange_rates[echangeRate.partner.id] = echangeRate.value;
          }
        }

        await this.$store.dispatch("investors/createFundInvestor", investorFormattedData);
        await this.getInvestors({ size: 10, filter: { type: "FUND" }, sort: { created_at: "DESC" } });

        this.closeDialog("dialogCreateInvestor", "formCreateInvestor");
      }
    },

    // Return if some partner is already selected in exchange rates
    isPartnerSelected(item) {
      return this.exchangeRates.some((e) => e.partner.id === item.id);
    },

    async handleBtnOpenDialogCreateInvestor() {
      await this.$store.dispatch("partners/getPartners");
      await this.$store.dispatch("currencies/getCurrencies");
      await this.$store.dispatch("investments/getInvestmentsSettings");
      this.dialogCreateInvestor = true;
    },

    // Close dialogs, clear and reset validation form fields
    closeDialog(dialogModel, formRef) {
      this.$refs[formRef].resetValidation();
      this[dialogModel] = false;

      this.investor = this.$options.data().investor;
      this.$refs.countryAutocomplete.selected = null;
      this.exchangeRates = this.$options.data().exchangeRates;
    }
  }
};
</script>
